.icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  &-edit {
    background-image: url("/assets/img/icons/edit.svg");
  }
  &-plus {
    background-image: url("/assets/img/icons/plus.svg");
  }
  &-save {
    background-image: url("/assets/img/icons/save.svg");
  }

  //Icon menu
  &-chargement {
    background-image: url("/assets/img/icons/icon-chargement.svg");
    width: 28px;
    height: 28px;
    margin-top: 5px;
  }
  &-dechargement {
    background-image: url("/assets/img/icons/icon-dechargement.svg");
    width: 28px;
    height: 28px;
    margin-top: 5px;
  }
  &-commandes {
    background-image: url("/assets/img/icons/icon-commandes.svg");
    width: 28px;
    height: 28px;
  }
  &-emballage {
    background-image: url("/assets/img/icons/icon-prixemballages.svg");
    width: 28px;
    height: 28px;
  }
  &-horaires {
    background-image: url("/assets/img/icons/icon-horaires.svg");
    width: 28px;
    height: 28px;
  }
  &-prixcolis {
    background-image: url("/assets/img/icons/icon-colis.svg");
    width: 28px;
    height: 28px;
  }
  &-ptrelais {
    background-image: url("/assets/img/icons/icon-relais.svg");
    width: 28px;
    height: 28px;
  }
  &-users {
    background-image: url("/assets/img/icons/icon-users.svg");
    width: 28px;
    height: 28px;
  }
}
