@import "./assets/scss/variables";
@import "~@ng-select/ng-select/scss/default.theme";
@import "./assets/scss/material-dashboard";
@import "./assets/scss/icons";
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~simple-line-icons/scss/simple-line-icons.scss';
@font-face {
  font-family: HelveticaNeue;
  src: url("./assets/fonts/HelveticaNeue Thin.ttf") format("truetype");
}
@font-face {
  font-family: HelveticaNeue;
  font-weight: bold;
  src: url("./assets/fonts/HelveticaNeueMed.ttf") format("truetype");
}
/* You can add global styles to this file, and also import other style files */
body {
  font-family: arial;
  font-size: 16px;
  background-color: #ffffff;
}
/* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.col-form-label {
  text-transform: uppercase;
}
.form-group {
  label {
    font-size: 14px;
    color: #7f8fa4;
    line-height: 1.67;
    font-weight: 500;
    margin-bottom: 0;
    display: block;
  }
  input, textarea {
    background-color: #ffffff!important;
    background: none;
    height: 36px;
    line-height: 36px;
    padding: 0 14px;
    border-radius: 4px;
    border: solid 1px #dfe3e9;
  }
}

.form-horizontal {
  .form-group {
    label {
      padding-top: 7px;
    }
  }
}

.form-control {
  @include material-placeholder() {
    color: #c3c3c3;
    opacity: 1;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 1.43;
    font-weight: normal;
  }
}

.sidebar {
  .logo a.logo-normal {
    text-align: center;
  }
  .user .photo {
    margin-top: 5px;
  }
}

.main-panel {
  .main-content {
    .card {
      margin-top: 0;
    }
  }
}

// MatToggle redesign
.mat-slide-toggle .mat-slide-toggle-thumb {
  width: 16px;
  height: 16px;
}

.mat-slide-toggle .mat-slide-toggle-bar {
  height: 20px;
  border-radius: 10px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #FFF;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #1facff !important;
}

.mat-slide-toggle .mat-slide-toggle-thumb-container {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  left: 0;
  right: 2px;
}

.control-switch {
  padding-top: 6px;
}

// Google Place Search
.ac-dropdown {
  position: absolute;
  background: #FFF;
  padding: 0;
  top: 36px;
  opacity: 1;
  z-index: 11;

  left: 15px;
  right: 15px;
  .list-group {
    border: 1px solid #eee;
    background-color: #eee;
    margin-top: -1px;
    &-item {
      color: #000;
      &:hover {
        color: #af002b;
      }
    }
  }
}

// Search List
.search-list {
  display: flex;
  .form-group {
    flex: 1;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      top: 11px;
      right: 10px;
      background-image: url(./assets/img/icons/search.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .form-control {
    padding-right: 34px;
    @include material-placeholder() {
      color: #354052;
      opacity: 1;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 1.43;
      font-weight: normal;
    }
  }
  .btn {
    margin-left: 31px;
  }
}

.back-btn-container {
  margin-bottom: 10px;
  .btn-back {
      padding-top: 10px;
      background-color: transparent !important;
      border: none !important;
      color: #000;
      padding-bottom: 13px;
      font-size: 18px;
  }
  h4.title {
      display: inline;
  }
}

.empty-block {
  p {
    font-family: "open Sans";
    font-size: 16px;
    font-weight: 600;
    color: #373737;
    margin-top: 39px;
    margin-bottom: 19px;
  }
}
p.form-control {
  background: none !important;
  line-height: 1.5;
}


.has-error {
  .form-label {
    color: #cc3333;
  }
  .form-control, .ng-select-container {
    border: solid 1px #cc3333;
  }
}

// fullcalendar
.fc-right {
  margin-top: 25px;
  margin-right: 15px;
  .fc-button-primary.fc-button-active {
    color: #fff!important;
    background-color: #1facff!important;
    border-color: #1facff!important;
  }
  .fc-button-primary {
    color: #b3b3b3!important;
    background-color: transparent!important;
    border-color: #b3b3b3!important;
  }
}

div.fc-left {
  color: transparent!important;
  h2 {
    cursor: default
  }
}
div.fc-center {
  margin-top: 20px;
  .fc-button-primary {
    height: 30px!important;
    width: 30px!important;
    padding: 0px!important;
    background: transparent!important;
    display: inline-block;
    border-color: #d8d8d8!important;
    span {
        color: #2c3e50!important;
        top: -1px;
        position: relative;
    }
  }
  h2 {
    font-size: 15px!important;
    font-weight: 600;
    top: 2px;
    position: relative;
    display: inline-block;
  }
}
div.fc-content {
  .fc-time {
    display: none;
  }
  .fc-title {
    font-weight: 200;
    padding-top: 5px;
    padding-left: 5px;
    color: #2e3031;
  }
  .fc-title::first-line {
    font-weight: 500;
    padding-top: 5px;
    padding-left: 0px!important;
    margin-left: -5px!important;
    position: relative!important;
    padding-bottom: 8px!important;
    margin-bottom: 12px!important;
    color: #2e3031; 
  }
}
h4.title {
  font-size: 28px!important;
  font-weight: 500!important;
  margin-top: 0px!important;
}
.title-modal {
  font-weight: 600;
  padding-left: 20px;
  color: #37373c!important;
}
.pad-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-round {
  width: 21px;
  height: 21px;
  text-align: center;
  background: #1facff; 
  margin-top: -29px;
  position: relative;
  z-index: 40;
  margin-right: 10px;
  border-radius: 50%;  
  div i, i {
    color: #fff;
    text-align: center
  }
}

.btn-transparent {
  background: #fff;
  border: 1px solid #cccccc;
  color: #000;
  margin-left: 5px;
  margin-right: 5px;
}


.highlight {
  display: inline-block;
  padding: 0 15px;
  position: relative;
}

.highlight:before,
.highlight:after {
  background: #c6c6c6;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 120%;
}
.highlight:before {
  right: 100%;
}
.highlight:after {
  left: 100%;
}

// class color checkbox
.custom-control-label.color-1::before {
  border-color: #4a90e2!important;
  background-color: #4a90e2!important;
}
.custom-control-label.color-2::before {
  border-color: #7eefe2!important;
  background-color: #7eefe2!important;
}
.custom-control-label.color-3::before {
  border-color: #ffc107!important;
  background-color: #ffc107!important;
}
.custom-control-label.color-4::before {
  border-color: #bfe758!important;
  background-color: #bfe758!important;
}
.custom-control-label.color-5::before {
  border-color: #3f51b5!important;
  background-color: #3f51b5!important;
}
.custom-control-label.color-6::before {
  border-color: #4f61c7!important;
  background-color: #4f61c7!important;
}
.custom-control-label.color-7::before {
  border-color: #ff5722!important;
  background-color: #ff5722!important;
}
.custom-control-label.color-8::before {
  border-color: #009688!important;
  background-color: #009688!important;
}
.custom-control-label.color-9::before {
  border-color: #795548!important;
  background-color: #795548!important;
}

.btn-group {
  margin: 0px 1px!important;
  .btn-light {
    padding: 0 15px!important;
    background: #f2f2f2!important;
    border: 2px solid #bbb!important;
    color: #000!important;
    border-radius: 4px!important;
    border-right: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
  }
  .dropdown-toggle {
    padding: 0 15px!important;
    border: 2px solid #bbb!important;
    border-radius: 4px!important;
    border-left: 0px!important;
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
  }
}
.dropdown-menu {
  left: 0px!important;
}

.mat-step-icon {
  background: #979797!important;
  height: 24px!important;
  width: 24px!important;
  font-size: 17px!important;
  font-weight: 600!important;
  color: #979797!important;
}

.mat-step-icon-selected {
  background: #1facff!important;
  height: 34px!important;
  width: 34px!important;
  font-size: 17px!important;
  font-weight: 600!important;
  color: #fff!important;
  margin-top: -5px!important;
}
.mat-step-icon-state-edit {
  background: #7ed321!important;
  color: #fff!important;
}
.mat-step-label {
  color: #979797!important;
  font-size: 16px!important;
  font-weight: 600!important;
}
.mat-step-label-selected {
  color: #1facff!important;
  font-size: 16px!important;
  font-weight: 600!important;
  padding-top: 11px!important;
}
.mat-horizontal-stepper-header-container {
  max-width: 275px!important;
}
/* card filter */
.card-filter {
  background-color: white;
  .card-body {
    margin: 0;
    padding: 0;
  }
} 
/* custom all table */
.card {
  .table-responsive.table-full-width {
    margin-left: -20px;
    width: calc(100% + 40px);
  }
  table {
    thead {
      border: 1px solid #f2f2f2;
      tr {
        th {
          font-size: 14px;
        }
        td {
          font-size: 16px;
        }
      }
    }
  }
  .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    font-size: 16px !important;
  }
}
/* modal padding content */
.mat-dialog-container {
  form.form-vertical {
    padding: 0 60px !important;
    label.col-form-label {
      padding-top: 0;
    }
  }
}
/* loader */
svg.circular.loading {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(87deg) brightness(500%) contrast(500%);
  height: 80%;
}
.mat-dialog-actions {
  display:  block !important;
}

.badge-statut {
  background-color: #fff;
  border:1px solid gray;
  font-size: bold;
  padding: 0px 15px;
  line-height: 25px;
  &.badge-lg {
    padding: 6px 18px;
    font-size: 14px;
  }
}
.tag {
  tag-input {
    width: 180px !important;
    .ng2-tag-input__text-input {
      max-width: 120px;
    }
    .ng2-tag-input {
      width: 180px !important;
      border-bottom: none !important;
      .ng2-tags-container {
        input.ng2-tag-input__text-input {
          &::placeholder {
            font-size: 11px !important;
          }
        }
        tag {
          border: 1px solid #1facff !important;
          border-radius: 5px !important;
          background-color: white !important;
          line-height: 15px !important;
          height: 21px !important;
          .tag__text {
            color:#1facff !important;
            font-size: 11px;
          }
          delete-icon {
            width: 12px !important;
            height: 8px !important;
            svg {
              height: 10px !important;
              vertical-align: middle;
            }
          }
        }
      }
    }

  }
}

.cdk-overlay-connected-position-bounding-box { z-index: 9999; }
  .choice {
    font-size: 20px;
    color: #c6c6c6;
    margin: 0px 10px;
  }
  .choice.active {
      color: #1facff;
  }
  .fc-time-grid-event {
      border: 1px solid rgba(0,0,0, 0.10) !important;
  }
  .block-list-calendar {
      width: 200px;
      height: auto;
      border-radius: 5px;
      cursor: pointer;
      .duree {
          position: absolute;
          right: 10px;
      }
      label {
          padding-top: 16px;
          font-size: 14px;
          font-weight: 600;
          color: #4a4a4a;
          margin-bottom: 1px;
          padding-left: 4px;
          cursor: pointer;
      }
      p {
          font-size: 12px;
          font-weight: 200;
          color: #4a4a4a;
          margin-bottom: 0px;
          padding-left: 7px;
      }
  }

.icon-arrow-down.is-menu {
  font-size: 10px !important;
}
p.form-control {
  border: none;
}
.label-value {
  font-size: 15px;
  font-size: 16px !important;
  color: #9b9b9b;
  text-align: left;
  font-weight: 400;
  padding-top: 0px;
  padding-bottom: 10px;
  margin-bottom: 0 !important;
}
a.text-default {
  color: #354052 !important;
}
.p-2.flex.bd-highlight {
  padding-left: 20px !important;
}
.md-drppicker {
  box-shadow: none !important;
  td.active, td.active:hover {
    background-color: #06a3ff !important;
  }
  .btn {
    background-color: #06a3ff !important;
  }
  td, th {
    &.month {
      text-transform: capitalize !important;
    }
    font-family: arial !important;
    font-size: 16px !important; 
  }
  .calendar-time.ng-star-inserted .select:first-child {
    select {
      padding-right: 3px !important;
    }
    &:after {
      content: 'h ';
    }
  }
  .buttons_input {
    text-align: center !important;
  }
}
.select-user {
  ng-select .ng-select-container span.ng-arrow-wrapper {
    display: none !important;
  }
}
ng-select.ng-select-single.ng-touched .ng-select-container {
  .ng-arrow-wrapper {
    margin-top: 5px !important;
  }
  .ng-value-container {
    margin-top: 8px !important;
  }
}
.sidebar {
  .logo .logo-img div {
    display: none;
  }
}
.sidebar-mini .sidebar {
  .nav li a, .sidebar .nav li .dropdown-menu a {
    margin: 0 15px 0 10px !important;
  }
  .logo .logo-img div {
    display: block;
    color: white;
    font-size: 32px;
  }
  .logo .logo-normal {
    display: none;
  }
}
.sidebar-toggler {
  background-color: #1b93d9;
  width: 12px;
  height: 110px;
  top: 45%;
  right: 0;
  position: absolute;
  cursor: pointer;
  z-index: 9999;
}

.readonly {
  div {
    .flex.p-2.ro {
      a {
        color: #333333 !important;
      }
    }
    .btn-round.add-btn.btn-just-icon.btn-sm.fixed.pull-right {
      background-color: #333333 !important;
    }
    td a {
      i {
        color: #333333 !important;
      }
    }
  }
}
a.custom-link {
  color: #354052;
  &:hover {
    color: #1facff;
  }
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  border-radius: .25rem;
  text-align: left;
  label {
    font-size: 14px;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 1px;
    padding-left: 4px;
    cursor: pointer;
  }
  p {
    font-size: 12px;
    font-weight: 200;
    color: #4a4a4a;
    margin-bottom: 0px;
    padding-left: 7px;
  }
}
.fc-time-grid .fc-slats td {
  min-height: 2.5em !important;
}
label.in-calendar {
  padding-top: 0 !important;
  .material-icons {
    font-size: 16px;
  }
}
div.fc-content {
  width: 100%;
  .block-list-calendar {
    width: auto !important;
  }
  .fc-time {
    display: none !important;
  }
  
}
.fc-event {
  &.late {
    border: 3px solid rgb(0, 0, 0) !important;
  }
}
.btn-full {
  width: 100%;
}
.md-drppicker.double {
    min-width: 510px;
    .buttons {
      text-align: right !important;
      .buttons_input {
        text-align: right !important;
        .btn.btn-default {
          background-color: #dcdcdc !important;
          margin-right: 10px;
          svg {
            display: none !important;
          }
        }
      }
      
    }
}