.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 324px;
  background: linear-gradient(to bottom, #7bceff 13%, #008ee1);

  .logo-img {
    width: 80px;
    max-height: 80px;

    img {
      width: 42px;
      top: 13px;
      left: 18px;
      position: absolute;
    }
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 324px;
    z-index: 4;

    padding-bottom: 30px;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
      box-shadow: none;

      .input-group {
        font-size: 1.7em;
        height: 36px;
        width: 78%;
        padding-left: 17px;
      }
    }

    > .nav,
    .user .user-info {
      [data-toggle='collapse'] ~ div > ul > li > a {
        span {
          display: inline-block;

          // @extend .animation-transition-general;
        }

        .sidebar-normal {
          margin: 0;
          position: relative;
          transform: translateX(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
          color: $white;
        }

        .sidebar-mini {
          text-transform: uppercase;
          width: 30px;
          margin-right: 15px;
          text-align: center;
          letter-spacing: 1px;
          position: relative;
          float: left;
          display: inherit;
          color: $white;
        }

        i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    margin-top: 15px;
    display: block;

    .caret {
      margin-top: 13px;
      position: absolute;
      right: 6px;
    }

    li {
      > a {
        &:hover,
        &:focus {
          background-color: transparent;
          outline: none;
        }
      }

      &:first-child > a {
        margin: 0 15px 0 18px;
      }

      &:hover > a,
      & .dropdown-menu a:hover,
      & .dropdown-menu a:focus,
      &.active > [data-toggle='collapse'] {
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, #4b88ad, #4b88ad);
        color: $black-color;
        box-shadow: none;
        border-radius: 5px;
      }

      &.active > [data-toggle='collapse'] {
        i {
          color: #a9afbb;
        }
      }

      &.active > a {
        border-radius: 5px;
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, #4b88ad, #4b88ad);
      }

      &.separator {
        margin: 15px 0;

        &:after {
          width: calc(100% - 30px);
          content: '';
          position: absolute;
          height: 1px;
          left: 15px;
          background-color: rgba(180, 180, 180, 0.3);
        }

        & + li {
          margin-top: 31px;
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 14px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      color: $white;
    }

    i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 28px;
      text-align: center;
      color: $white;
    }
  }

  .nav li a,
  .nav li .dropdown-menu a {
    margin: 0 15px 0 18px;
    border-radius: $border-radius-base;
    color: $black-color;
    padding: 0.9rem 15px;
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-size: $font-paragraph + 1;
    font-weight: bold;
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      background: #ffffff;
      opacity: 0.93;
    }
  }

  .logo {
    padding: 15px 0px;
    margin: 0;
    display: block;
    position: relative;
    z-index: 4;

    a.logo-mini,
    a.logo-normal {
      @extend .animation-transition-general;
    }

    a.logo-mini {
      opacity: 1;
      float: left;
      width: 30px;
      text-align: center;
      margin-left: 23px;
      margin-right: 15px;
    }

    a.logo-normal {
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background-color: rgba(255, 255, 255, 0.6);
    }

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: inline-block;
      font-size: 18px;
      color: $black-color;
      white-space: nowrap;
      font-weight: $font-weight-default;
      line-height: 30px;
      overflow: hidden;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .user {
    margin: 0 auto;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background-color: rgba(255, 255, 255, 0.6);
    }

    .photo {
      width: 34px;
      height: 34px;
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 11px;
      border-radius: 50%;
      margin-left: 23px;
      @include shadow-big();
      @extend .animation-transition-general;

      img {
        width: 100%;
      }
    }

    a {
      color: $black-color;
      padding: $padding-base-vertical 15px;
      white-space: nowrap;
    }

    .user-info {
      > a {
        display: block;
        line-height: 2.5;
        font-size: 12px;
        font-weight: 300;
        text-align: center;

        > span {
          @extend .animation-transition-general;
          display: inline-block;
          position: relative;
          opacity: 1;
          color: $white;
        }
      }

      .caret {
        top: 8px;
        right: 15px;
      }

      .nav {
        margin-top: 0;
      }
    }
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }
}

.off-canvas-sidebar .navbar-collapse {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white-color;
      margin: 0 15px;
    }

    > li > a:focus,
    > li > a:hover {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

.main-panel {
  position: relative;
  float: right;
  width: $sidebar-width;
  background: $white-theme-panel;
  @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  .main-content {
    padding: 23px 13px 30px 13px;
    min-height: calc(100vh - 123px);
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}

.visible-on-sidebar-mini {
  display: none !important;
}

@media (min-width: 991px) {
  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }

    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      font-weight: 200;
      z-index: 9999;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user
          .user-info
          [data-toggle='collapse']
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .user-info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    /*.sidebar:hover {
      width: 324px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0px);
        }
      }

      .sidebar-wrapper {
        width: 324px;

        > .nav li > a p,
        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user
          .user-info
          [data-toggle='collapse']
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .user-info > a > span {
          @include transform-translate-x(0px);
          opacity: 1;
        }
      }
    } */
  }
}

@media screen and (min-width: 991px) {
  .hide-sidebar {
    .sidebar {
      @include transition-all($general-transition-time, $transition-linear);

      @include transform-translate-x(-324px);
    }

    .main-panel {
      width: 100%;
    }

    &.sidebar-mini {
      .sidebar {
        @include transition-all($general-transition-time, $transition-linear);

        @include transform-translate-x(-80px);
      }
    }
  }

  .animation {
    @include transition-all($general-transition-time, $transition-linear);
  }
}
